import Vue from 'vue'
import Router from 'vue-router'
import {routes} from "./routes"

Vue.use(Router)
export default new Router({
    mode: 'history',
    routes
})
// 处理重复点击报错问题
const originalPush = Router.prototype.push;
const originalreplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
Router.prototype.replace = function push(location) {
    return originalreplace.call(this, location).catch((err) => err);
};