<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Fingerprint2 from "fingerprintjs2";
export default {
  mounted() {
    this.getBrowserCode();
  },
  methods: {
    // 获取浏览器唯一码
    getBrowserCode() {
      Fingerprint2.get((components) => {
        // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
        const values = components.map((component) => component.value); // 配置的值的数组
        const browserCode = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
        window.sessionStorage.setItem("browserCode", browserCode);
      });
    },
  },
};
</script>
<style lang="less">
@import url("./libs/css/reset.css");
@import url("./libs/css/common.less");
</style>
