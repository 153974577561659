export const setHtmlFontSize = () => {
    const htmlDom = document.getElementsByTagName('html')[0];
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (htmlWidth <= 750) {
        htmlWidth = 750;
    }
    if (htmlWidth >= 1920) {
        htmlWidth = 1920;
    }
    htmlDom.style.fontSize = `${htmlWidth / 1920}px`;
};