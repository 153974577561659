export const children = [
    {
        path: '/',
        name: 'constructing',
        component: () => import('@/views/landingPage.vue')
    },
    {
        path: "*",
        redirect: {name:"constructing"}
    }
]