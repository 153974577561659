import {children} from "./children"
export const routes = [
    {
        path: '/',
        // name: 'system',
        component: () => import("../components/System"),
        children
    },
    {
        path: "*",
        redirect: "/"
    }
]