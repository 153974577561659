import axios from "axios";
import { message } from "ant-design-vue";
let token;
export const http = axios.create({
    "Cache-Control": false,
    baseURL: process.env.VUE_APP_BASEURL,
    timeout: 30 * 1000,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
})

http.interceptors.request.use(
    config => {
        token = localStorage.getItem("token") || "";
        config.headers.Authorization = token; //设置token
        return config;
    },
    err => {
        return Promise.reject(err)
    }
)
http.interceptors.response.use(
    res => {
        const { data } = res;
        if (data.errorCode == 1) {
            clearCatch();
            message.error("Account expired, please log in again.");
            let T = setTimeout(() => {
                clearTimeout(T)
                window.location.href = "/404";
            }, 1000);
            return 
        } else {
            if (data.errorCode !== 0) {
                message.error(data.errorMsg);
                return Promise.reject(data.errorMsg);
            } else {
                return data.data;
            }
        }
    },
    err => {
        return Promise.reject(err);
    }
)
function clearCatch() {
    window.localStorage.clear()
}
