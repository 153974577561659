import { http } from './http'
import axios from 'axios'
import { rsaEncrypt } from './tools'
//  ----------------手机验证----------------
//#region
/**
 *新增检验手机验证码是否正确
 * @param {string} verifyCode 验证码
 * @returns promise of axios
 */
export const changePhoneVerifyPhone = verifyCode => {
    return http.post('/app/user/changePhoneVerifyPhone', {
        verifyCode
    })
}

//
/**
 *新增检验邮箱验证码是否正确
 * @param {string} verifyCode 验证码
 * @returns promise of axios
 */
export const changePhoneVerifyEmail = verifyCode => {
    return http.post('/app/user/changePhoneVerifyEmail', {
        verifyCode
    })
}

/**
 *新增使用邮箱验证码绑定手机
 * @param {string} account email
 * @param {string} phone phone
 * @param {string} verifyCode 验证码
 * @returns promise of axios
 */
export const bind_phone = (account, phone, verifyCode) => {
    return http.post('/app/user/bind_phone', {
        account,
        phone,
        verifyCode
    })
}
// #endregion
//  ----------------登录注册相关----------------
//#region
/**
 *
 * @param {string} account email
 * @param {string} pwd password
 * @param {string} inviter 邀请者
 * @param {string} inviteType 邀请类型
 * @returns promise of axios
 */
export const register = (account, pwd, inviter, inviteType) => {
    const psw = rsaEncrypt(pwd)
    return http.post('/app/user/sign_up', {
        account,
        psw,
        inviter,
        inviteType
    })
}

/**
 * 文件上传 varbinary
 * @param {Varbinary} file file
 * @param {String} path path
 * @returns promise of axios
 */
export const uploadClick = (file, path) => {
    return http.post(
        '/app/file/upload',
        {
            file,
            path
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
    )
}

/**
 * 文件下载
 * @param {string} path path
 * @param {string} context context
 * @returns promise of axios
 */
export const downloadClick = (path, context) => {
    return http.post('/app/file/download', {
        path,
        context
    })
}

/**
 * 修改头像
 * @param {string} avatar avatar
 * @returns promise of axios
 */
export const editAvatar = avatar => {
    return http.post('/app/user/edit', {
        avatar
    })
}

/**
 * 头像框列表
 * @returns promise of axios
 */
export const getFrameList = () => {
    return http.post('/app/frame/list', {})
}

/**
 * 设置头像框
 * @param {string} frameId frameId
 * @returns promise of axios
 */
export const saveFrame = (frameId) => {
    return http.post('/app/frame/save', { frameId })
}

/**
 * login by email
 * @param {string} email email as account
 * @param {string} pwd  pwd as password
 * @param {string} inviter 邀请者
 * @param {string} inviteType 邀请类型

 * @returns promise of axios
 */

export const login = (account, pwd, inviter, inviteType) => {
    const psw = rsaEncrypt(pwd)
    return http.post('/app/user/log_in', {
        account,
        psw,
        inviter,
        inviteType
    })
}

/**
 * google login authorization
 * @param {string} invite inviter&inviteType
 * @returns promise of axios
 */
export const getGoogleAuth = invite => {
    return http.post('/app/user/google_oauth2_redirect', {
        context: invite
    })
}

/**
 * google login
 * @param {string} state google auth state
 * @param {string} code google auth code
 * @returns promise of axios
 */
export const googleLogin = (state, code) => {
    return http.post('/app/user/google_code_auth', { state, code })
}

/**
 * 登出
 * @returns promise of axios
 */

export const signOut = () => {
    return http.post('/app/user/sign_out', {})
}

/**
 * getUserInfo by userId
 * @param {string} userId userId
 * @returns promise of axios
 */
export const requireUserInfo = userId => {
    const token = window.localStorage.getItem('token') || ''
    if (!token) return {}
    return http.post('/app/user/user_profile', { userId })
}
//#endregion

// ----------------marketPlace 相关----------------
//#region
/**
 * get tiers and location
 * @returns promise of axios
 */
export const getMarketPlaceTag = () => {
    return http.post('/app/trait/marketplace', {})
}

/**
 * getNftList
 * @param {string} convert usd eth brick
 * @param {number} page
 * @param {number} size
 * @param {Array<object>} traits traits-->trait_type    value-->val
 * @returns promise of axios
 */

export const getNftListBySearch = (
    convert,
    page,
    size,
    collections = [],
    traits = []
) => {
    return http.post('/app/nft/searchNFTByTraits', {
        convert,
        page,
        size,
        traits,
        collections
    })
}

/**
 * 获取nft详情(用于购买)
 * @param {string} nftId nftId
 * @returns promise of axios
 */
export const getNftDetail = nftId => {
    return http.post('/app/nft/nftDetail', {
        nftId
    })
}

/**
 * 获取nft的拥有者列表
 * @param {string} nftId
 * @param {number} page 分页数
 * @returns promise of axios
 */
export const getOwnerList = (nftId, page) => {
    return http.post('/app/collected/ownerList', {
        nftId,
        size: 10,
        page
    })
}
//#endregion

// ----------------myProfile相关----------------
//#region
/**
 * 领取新手大礼包
 * @returns promise of axios
 */
export const keepGift = () => {
    return http.post('/app/coupon/new_user_coupon', {})
}

/**
 * 获取KYC认证需要的stepId和sessionId
 * @returns promise of axios
 */
export const getKycCode = () => {
    return http.post('/app/user/kyc_apply', {})
}

/**
 * KYC认证成功之后将相应数据提交至后台
 * @param {string} address 地址
 * @param {string} city 城市
 * @param {string} state 省份、州
 * @param {string} country  国家
 * @param {string} assets 美国用户时资产证明
 * @param {string} assetsType 文件类型，图片类型
 * @returns
 */
export const handleSubmitKycData = ({
    address,
    city,
    state,
    assets,
    assetsType,
    country
}) => {
    return http.post('/app/user/kyc_submit', {
        address,
        city,
        state,
        assets,
        assetsType,
        country
    })
}

/**
 * 通信第三方提交KYC认证资料
 * @param {object} params body params
 * @param {string} sessionId header config session-id
 * @param {string} stepId query
 * @returns promise of axios
 */

export const handleValidteKYC = (params, sessionId, stepId) => {
    return axios({
        method: 'post',
        url: 'https://individual-api.synaps.io/v3/identity/submit',
        headers: {
            'Session-Id': sessionId,
            'Content-Type': 'multipart/form-data'
        },
        params: {
            step_id: stepId
        },
        data: params
    })
}

/**
 * 发送忘记密码邮件
 * @param {string} email user email
 * @returns promise of axios
 */
export const sendEmailByforgetPwd = email => {
    return http.post('/app/user/send_forget_psw_email', { email })
}
/**
 * 使用验证码重置-修改密码（已登录）
 * @param {string} newPsw1 newPsw
 * @param {string} verifyCode verifyCode
 * @returns promise of axios
 */
export const resetPSW = (newPsw1, verifyCode) => {
    const newPsw = rsaEncrypt(newPsw1)
    return http.post('/app/user/reset_psw_verify', { newPsw, verifyCode })
}

/**
 * 发送验证邮箱和修改密码
 * @param {string} operation verifyEmail  resetPsw
 * @returns promise of axios
 */
export const sendEmailByValidateOrChangePwd = (operation, account) => {
    return http.post('/app/user/send_verify_code_email', { operation, account })
}

/**
 * 获取用户藏品列表
 * @param {string} userId 用户id
 * @returns promise of axios
 */
export const getUserNftList = userId => {
    return http.post('/app/collected/collectedList', { userId })
}

/**
 * 根据分类获取用户藏品列表
 * @param {string} name name
 * @param {string} userId 用户id
 * @returns promise of axios
 */
export const getUserNftListByName = (userId, name) => {
    return http.post('/app/collected/collection_by_name', { userId, name })
}

/**
 * 获取用户nft详情
 * @param {string} collectedId user nftId
 * @returns promise of axios
 */
export const getUserNftDetail = collectedId => {
    return http.post('/app/collected/collectedDetail', { collectedId })
}

/**
 * 修改用户昵称
 * @param {string} nickname 修改昵称
 * @returns promise of axios
 */
export const changeUserNickname = nickname => {
    return http.post('/app/user/edit_nickname', { nickname })
}

/**
 * 忘记密码
 * @param {string} newPsw1 新密码
 * @param {string} verifyCode 邮箱码
 * @returns promise of axios
 */
export const resetPwd = (newPsw1, verifyCode, email) => {
    const newPsw = rsaEncrypt(newPsw1)
    return http.post('/app/user/forget_psw_verify', { newPsw, verifyCode, email })
}

/**
 * 验证邮箱
 * @param {string} verifyCode 邮箱码
 * @returns promise of axios
 */
export const verifyEmail = (account, verifyCode) => {
    return http.post('/app/user/verify_email_verify', {
        account,
        verifyCode,
        inviter: localStorage.getItem('inviter'),
        inviteType: localStorage.getItem('inviteType')
    })
}

/**
 * 提交社交账号
 * @param {array} mediaList twitter
  "mediaList": [
    {
      "id": "",
      "mediaId": "",
      "mediaName": "",
      "mediaType": "",
      "userId": ""
    }
  ]
 * @returns promise of axios
 */
export const submitSocialMedia = (mediaList) => {
    return http.post('/app/user/complete_social_media', { mediaList })
}

/**
 * 回显社交账号
 * @param {string} userId userId
 * @returns promise of axios
 */
export const getSocialMedia = userId => {
    return http.post('/app/user/user_social_media', { userId })
}

/**
 * 链接推特
 * @returns promise of axios
 */
export const toLinkTwitter = () => {
    return http.post('/app/twitter/login', {})
}
/**
 * 获取推特信息
 * @param {string} url url
 * @returns promise of axios
 */
export const getTwitterInfo = url => {
    return http.get(url)
}

/**
 * 链接discord
 * @returns promise of axios
 */
export const toLinkDiscord = () => {
    return http.post('/app/discord/login', {})
}
/**
 * 获取discord信息
 * @param {string} url url
 * @returns promise of axios
 */
export const getDiscordInfo = url => {
    return http.get(url)
}

/**
 * 获取tgCode
 * @param {string} code code
 * @returns promise of axios
 */
export const getTGCode = code => {
    return http.post('/app/telegram/getCode', { code })
}
/**
 * 链接tg
 * @returns promise of axios
 */
export const toLinkTelegram = () => {
    return http.post('/app/telegram/login', {})
}
/**
 * 获取tg信息
 * @param {string} url url
 * @returns promise of axios
 */
export const getTelegramInfo = url => {
    return http.get(url)
}

/**
 * 获取优惠券list
 * @param {number} page 分页
 * @param {number} usedStatus coupon(不传查全部)状态 0=未使用，1=已使用， 2=已过期 3=传全部
 * @returns
 */
export const getUserCoupon = (page, usedStatus) => {
    return http.post('/app/user/couponList', { size: 1000, page, usedStatus })
}

/**
 * 兑换优惠券
 * @param {number} code code
 * @returns
 */
export const redeemCoupon = code => {
    return http.post('/app/user/redeem', { code })
}


/**
 * 获取全部钱包
 * @param {string} walletAddress 钱包地址
 * @returns
 */
export const getAllWalletAdd = () => {
    return http.post('/app/userCryptoWallet/get', {})
}

/**
 * 钱包签名——获取nonce
 * @param {string} walletAddress 钱包地址
 * @returns
 */
export const getNonceByAdd = walletAddress => {
    return http.post('/app/userCryptoWallet/verify', { walletAddress })
}

/**
 * 添加电子加密钱包
 * @param {string} signature 签名
 * @param {string} walletAddress 钱包地址
 * @returns
 */
export const bindWalletAdd = (walletAddress, signature) => {
    return http.post('/app/userCryptoWallet/add', { walletAddress, signature })
}

/**
 * 修改钱包（名字或默认状态）
 * @param {string} id 钱包地址
 * @param {string} name 钱包名字
 * @param {string} status 钱包状态
 * @returns
 */
export const editWallet = (id, name, status) => {
    return http.post('/app/userCryptoWallet/edit', { id, name, status })
}
/**
 * 删除钱包
 * @param {string} id 钱包地址
 * @returns
 */
export const delWallet = (id) => {
    return http.post('/app/userCryptoWallet/delete', { id })
}

/**
 * 获取balance历史
 * @param {string} type 1:收入 2支出
 * @param {string} time 年月——秒级时间戳
 * @param {string} page 
 * @param {string} size 
 */
export const getBalanceHistory = (time, type, page = 1, size = 1000) => {
    return http.post('/app/userDividend/getHistory', { time, type, page, size })
}

/**
 * 获取提现记录
 * @param {string} time 年月——秒级时间戳
 * @param {string} page 
 * @param {string} size 
 */
export const getWithdrawRecord = (time, page = 1, size = 1000) => {
    return http.post('/app/userDividend/getWithdrawRecord', { time, page, size })
}

/**
 * 提现详情
 * @param {string} id 交易id
 */
export const getWithdrawRecordDetail = (id) => {
    return http.post('/app/userDividend/withdraw_detail', { id })
}

/**
 * 发送提现验证码
 * @param {string} email
 */
export const getWithdrawCode = (email) => {
    return http.post('/app/userDividend/send_verify_email', { email })
}

/**
 * 计算手续费
 * @param {number} amount
 */
export const calcFee = (amount) => {
    return http.post('/app/userDividend/calculated_withdraw_price', { amount })
}

/**
 * 申请提现
 * @param {string} amount 美分
 * @param {string} code 验证码
 * @param {string} description  
 * @param {string} walletId 钱包地址
 */
export const applyWithdraw = (amount, code, description, walletId) => {
    return http.post('/app/userDividend/apply_withdraw', { amount, code, description, walletId })
}

//#endregion

// ----------------订单相关----------------
//#region
/**
 * 检查nft库存情况和用户状态
 * @param {string} nftId
 * @param {number} userId
 * @param {string} name
 * @returns promise of axios
 */
export const checkNftAndUserStatus = (nftId, userId, name) => {
    return http.post('/app/nft/checkNFTITemAvailability', { nftId, userId, name })
}

/**
 * 锁定nft库存并生成订单
 * @param {string} nftId
 * @param {number} userId
 * @returns promise of axios
 */
export const lockNftAndPlaceOrder = (nftId, userId) => {
    return http.post('/app/order/place', { nftId, userId })
}

/**
 * 查询订单状态
 * @param {string} orderNo 订单号
 * @returns promise status 0=待支付，1=支付中，2=取消， 3=支付成功，4=支付失败
 */
export const checkOrderStatus = orderNo => {
    return http.post('/app/order/checkOrderStatus', { orderNo })
}

/**
 * 获取支付地址
 * @param {string} orderNo 订单号
 * @returns Promise of axios
 */
export const getPayAddress = orderNo => {
    return http.post('/app/order/getAddress', { orderNo })
}

/**
 * 获取订单信息
 * @param {string} orderNo 订单号
 * @returns Promise of axios
 */
export const getOrderInfo = orderNo => {
    return http.post('/app/order/getAddress', { orderNo })
}

/**
 * 订单支付
 * @param {object} params 订单支付所需相关数据
 * {
    billingDetail: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postalCode: "",
        state: ""
    },
    card: {
        cvv: "",
        expireDate: "",
        firstName: "",
        lastName: "",
        number: ""
    },
    cardId: "",
    ip: "",
    orderNo: "",
    payType: "",
    userId: ""
    }
 * 
 * @returns promise of axios
 */
export const payment = params => {
    if (params.card) {
        params.card.cvv = rsaEncrypt(params.card.cvv)
    }
    return http.post('/app/order/payment', { ...params, appType: "web", gateway: 'circle' })
}

//#endregion


// ----------------metamask相关----------------
//#region

/**
 * 获取订单的优惠卷列表
 * @param {string} nftId 产品nftId
 * @param {string} orderNo 订单id（存在订单时）
 * @returns {array} nft列表
 */
export const getOrderCouponList = (nftId, orderNo = null) => {
    return http.post("/app/coupon/list", { nftId, orderNo })
}
/**
 * 美元转换其他单价
 * @param {number} price 美元价格
 * @param {string} payType 转换类型 "ETH" "USDC" "USDT" "DAI" "WBTC"
 * @returns promise of axios
 */
export const transformPrice = (price, payType) => {
    return http.post('/app/nft/usdConvertETH', { price, payType })
}

/**
 * 钱包支付
 * @param {string} orderNo 订单号
 * @param {string} payType 支付方式 "ETH" "USDC" "USDT" "DAI" "WBTC"
 * @param {string} couponId 优惠券ID
 * @param {string} appType 应用端 web=电脑网页 ，ios=苹果，android=安卓
 * @param {string} gateway 支付平台 "metamask" "circle"
 * @returns 
 */
export const paymentAsMetamask = (orderNo, payType, couponId = "", appType = "web", gateway = "metamask") => {
    return http.post('/app/order/payment', { orderNo, payType, couponId, gateway, appType })
}

/**
 * 计算使用优惠券后的价格
 * @param {string} nftId 藏品的nftiId
 * @param {string} userCouponId 优惠券id
 * @param {string} orderNo 订单id（存在订单时）
 * @returns 
 */
export const calculateUseCouponPrice = (nftId, userCouponId, orderNo = null) => {
    return http.post("/app/coupon/calculate", { nftId, userCouponId, orderNo })
}

/**
 * 获取订单详情
 * @param {string} orderNo 订单号
 * @returns orderInfo
 */
export const requireOrderInfo = (orderNo) => {
    return http.post('/app/order/getOrderDetail', { orderNo })
}

/**
 * 用户支付完成后回调
 * @param {string} orderNo 订单号
 * @param {boolean} success 订单支付结果
 * @returns 
 */
export const userPaidToCall = (orderNo, success) => {
    return http.post("/app/order/userPaid", { orderNo, success })
}

/**
 * 获取会员信息和会员打折后的价格
 * @param {number} price 商品价格，用于获取会员计算后的价格
 * @returns 
 */
export const requireMemberInfo = (price) => {
    return http.post("/app/order/getMemberShipDetail", { price })
}

//#endregion


// ----------------game相关----------------
//#region
export const getGameBanner = () => {
    // params.card.cvv = rsaEncrypt(params.card.cvv)
    // return http.post('/app/order/payment', params)
}
//#endregion

// ----------------brick相关----------------
//#region

/**
 * 获取用户brick历史
 * @param {number} beginTime 开始时间
 * @param {number} type 0所有 1收入 2支出
 * @returns Promise of axios
 */
export const getBrickHistory = (beginTime, type) => {
    return http.post('/app/wallet/brick_history', { beginTime, type })
}

/**
 * 获取用户missions
 * @param {string} userId 用户id
 * @param {number} missionType 0 daily  1 one-time  2-special
 * @returns Promise of axios
 */
export const getMissionList = (userId, missionType) => {
    return http.post('/app/mission/list', { userId, missionType })
}

/**
 * 获取用户missions的历史记录
 * @param {string} userId 用户id
 * @returns promise of axios
 */
export const getMissionHistoryList = userId => {
    return http.post('/app/mission/history', { userId })
}

/**
 * 获取用户missions的历史记录
 * @param {string} userId 用户id
 * @param {string} missionId missionId
 * @returns promise of axios
 */
export const getMissionLink = (userId, missionId) => {
    return http.post('/app/mission/goto', { userId, missionId })
}

/**
 * estates兑换bricks
 * @param {number} estates estates额
 * @returns promise of axios
*/
export const estatesToBricks = (estates) => {
    return http.post('/app/useEstates/exchange', { estates })
}

/**
 * 获取用户discord的estate数量
 * @returns promise of axios
*/
export const getDiscordEstate = () => {
    return http.post('/app/useEstates/getDiscordEstateBalance', {})
}

/**
 * 获取exchange记录状态
 * @param {string} useEstatesLogId exchange记录ID	
 * @returns promise of axios
 */
export const getExchangeRecord = (useEstatesLogId) => {
    return http.post('/app/useEstates/getExchangeStatus', { useEstatesLogId })
}

/**
 * 领取奖励
 * @param {string} missionsId 任务id
 * @returns 
 */
export const getRewards = (missionsId) => {
    return http.post("/app/mission/getRewards", { missionsId })
}

/**
 * 获取每日任务task bar 任务明细
 * @returns 
 */
export const requireDailyTaskBar = () => {
    return http.post("/app/mission/getTaskBarDetail", {})
}

/**
 * 领取每日完成任务的积分
 * @param {string} missionId 任务id
 * @returns 
 */
export const queryDailyScore = (missionId) => {
    return http.post("/app/mission/getMissionsScores", { missionId })
}

/**
 * 领取满足积分要求的gift获取奖励
 * @param {string} missionTaskId 可领取的taskId
 * @returns 
 */
export const queryDailyTaskBarReward = (missionTaskId) => {
    return http.post("/app/mission/getTaskBarReward", { missionTaskId })
}

/**
 * 获取特殊任务（邀请任务）邀请任务详情
 * @param {number} page 页数
 * @param {number} size 条数
 * @returns 
 */
export const querySpecialInviteDetail = (page, size = 9) => {
    return http.post("/app/mission/getInviteMissionDetail", { page, size })
}

/**
 * 获取邀请者的奖励
 * @param {string} missionId missionId
 * @param {string} invitee 被邀请人的Id，如果 不传 ，则是all
 */
export const queryInviteRewards = (missionId, invitee = null) => {
    return http.post("/app/mission/getInviteRewards", { invitee, missionId })
}

//#endregion

// ----------------community相关----------------
//#region
/**
 * 搜索用户
 * @param {string} keyword 关键词
 * @param {number} page 页码
 * @param {number} size 页容
 * @returns Promise of axios
 */

export const searchCollector = (keyword, page = 1, size = 10) => {
    return http.post('/app/collected/search', { keyword, page, size })
}

/**
 * 搜索用户
 * @param {string} userId 用户Id
 * @returns Promise of axios
 */

export const InviterUser = userId => {
    return http.post('/app/user/user_invite_detail', { userId })
}

/**
 * 砍一刀
 * @param {string} inviter 用户Id
 * @param {string} type 活动类型: 1 = FOMO SALE, 2 = Claim a Piece
 * @param {string} useGoldenTicket 是否使用GoldenTicket，默认是false不使用
 * @returns Promise of axios
 */
export const claimOnePiece = (inviter, type, useGoldenTicket) => {
    return http.post('/app/user/claim', { inviter, type, useGoldenTicket })
}

/**
 * 获取钥匙
 * @returns Promise of axios
 */
export const keepTheKey = () => {
    return http.post('/app/claimpiece/get', {})
}

/**
 * 开始砍一刀
 * @returns Promise of axios
 */
export const startClaimOnePiece = () => {
    return http.post('/app/claimpiece/start', {})
}

/**
 * 获取ClaimPiece详情
 * @param {string} userId 用户Id
 * @param {string} ownerId 登录者Id
 * @returns Promise of axios
 */

export const getClaimPieceDetail = (userId, ownerId) => {
    return http.post('/app/claimpiece/detail', { userId, ownerId })
}
/**
 * 获取Claim History
 * @param {number} page page
 * @param {number} size size
 * @param {string} userId userId
 */
export const getClaimHistory = (userId, page = 1, size = 100) => {
    return http.post('/app/claimpiece/history', { userId, page, size })
}

/**HammerTime初始化获取
 *
 * @param {number} size
 * @returns Promise of axios
 */
export const initHammerTime = size => {
    return http.post('/app/interactive/init', { size })
}
/**HammerTime刷新
 *
 * @param {number} topUnix
 * @returns Promise of axios
 */
export const updateHammerTime = topUnix => {
    return http.post('/app/interactive/refresh', { topUnix })
}
/**HammerTime翻页
 *
 * @param {number} size
 * @returns Promise of axios
 */
export const nextHammerTime = (size, unix) => {
    return http.post('/app/interactive/next_page', { size, unix })
}
/**HammerTime点赞
 *
 * @param {string} inviteId 邀请链接的id
 * @param {string} type 类型 fomoSale 或者claim piece
 * @returns Promise of axios
 */
export const toLikeHammerTime = (inviteId, type) => {
    return http.post('/app/interactive/like', { inviteId, type })
}
/**HammerTime前三
 *
 * @returns Promise of axios
 */
export const getTopThree = () => {
    return http.post('/app/interactive/top_like', {})
}

/**ShowOffs榜单
 *
 * @param {string} nftId
 * @param {number} page
 * @param {number} size
 * @returns Promise of axios
 */
export const rankShowOffs = (nftId, page, size) => {
    return http.post('/app/interactive/collected_rank', { nftId, page, size })
}

//#endregion
// ----------------手机验证码相关----------------
//#region
/**
 * 获取拼图
 * @param {string} browserCode
 * @param {number} type
 * @returns Promise of axios
 */
export const getPuzzle = (
    browserCode = sessionStorage.getItem('browserCode'),
    type = 0
) => {
    return http.post('/app/puzzle/get', { browserCode, type })
}
/**
 * 验证拼图,并发送验证码
 * @param {string} x
 * @param {number} id
 * @param {string} phone
 * @param {string} userId
 * @returns Promise of axios
 */
export const verifyPuzzle = (x, id, phone, userId) => {
    return http.post('/app/puzzle/verify', { x, id, phone, userId })
}

/**
 * 验证码绑定手机
 * @param {string} mobileCode
 * @param {string} phone
 * @param {number} verifyCode
 * @returns promise of axios
 */
export const verifyPhoneCode = (mobileCode, phone, verifyCode) => {
    return http.post('/app/user/bind_phone', {
        mobileCode,
        phone,
        verifyCode
    })
}

//#endregion

// ----------------客服AI相关----------------
// #region
/**
 * 获取消息
 * @returns {string}
 */
export const getMessage = () => {
    return http.post("/app/telegram/getMsg", {})
}

/**
 * 发送消息
 * @param {string} message 信息内容
 * @returns 
 */
export const sendMessage = (message) => {
    return http.post("/app/telegram/postMsg", { message })
}

/**
 * 数据埋点，统计页面点击数
 * @param {string} url 页面path
 */
export const sendPagePathToSave = (url) => {
    return http.post("/app/access/save", { url })
}
// #endregion

/** 添加通知邮件
 * @param email email
 */
export const notify_email = (email) => {
    return http.post("/app/notify/email", { email })
}