import { pki } from "node-forge";
import { http } from "./http";

export async function getFilesPath(pathArr) {
    return new Promise(resolve => {
        let count = 0;
        for (let i = 0; i < pathArr.length; i++) {
            const element = pathArr[i];
            http.post("/app/file/download", { path: element.media }).then(({ url }) => {
                element.media = url;
                if (++count >= pathArr.length) resolve();
            });
        }
    });


}

/**
 * 图片地址转base64
 * @param {string} url img路径
 */
export function imgPathToBase64(url) {
    return new Promise(resolve => {
        let canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d'),
            img = new Image;
        img.crossOrigin = 'Anonymous';
        img.src = /^data:image/.test(url) ? url : url + '?' + new Date().getTime();
        img.onload = function () {
            canvas.height = img.height;
            canvas.width = img.width;
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
            canvas = null;
        };
    });
}
//base64转file对象
export function base64toFile(base, filename) {
    const arr = base.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const suffix = mime.split("/")[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    //转换成file对象
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    });
}
/**
 * file 转 base64
 * @param {object} file 
 * @returns promise of base64
 */
export function fileToBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise(function (resolve) {
        reader.addEventListener("load", function () {
            return resolve(reader.result);
        });
    });
}
/**
 * 密码加密
 * @param {string} data 
 * @returns rsa data
 */
export function rsaEncrypt(data) {
    // 定义公钥
    const pemStr = `-----BEGIN RSA PUBLIC KEY-----
            MIIBCgKCAQEAsJfF5FE/B7RbssuKJae+MsWQ8BUJVYR9W4cz7VBBo6gBcV2jHviA
            B53r2gDQ+oTL7SDYmNtaGOX7Q6OoSKRm3eFHDM44HeGgdyRiLNNsnSeMAOGDJ9Yx
            PmLhbQwfYEjbntVcK18dLPsTFcOuPCvOeajXSvbsZVEidRPL+PNDJ+iiz5tDan0d
            v4wtD3zAvWD2nK97fuu6Guo3CCgIf9f2aPWEDxBlNOKb7IBeQDTp9ZKuya2lJtzT
            tBuREPcF2P/QYW7yo+54sLB9/Xn9W/bbiY07SOPOByRYSyyQV/B09VF0lTEn5ps6
            l/oG7yxiVBFqLx6XxkTr3/mtdE4aKCwchwIDAQAB
                    -----END RSA PUBLIC KEY-----`;
    let publicKey = pki.publicKeyFromPem(pemStr);
    // 获取到rsa加密结果
    let encrypted = publicKey.encrypt(data, 'RSAES-PKCS1-V1_5');
    // rsa加密后转base64
    return window.btoa(encrypted);
}

/** 倒计时转单独 */
export function countdownIndependent(stamp, type) {
    let time = +stamp - Math.floor(Date.now() / 1000);
    if(time<=0){
        return '00'
    }
    let dd = Math.floor(time / 86400);
    let hh = Math.floor((time % 86400) / 3600);
    let mm = Math.floor((time % 3600) / 60);
    let ss = time % 60;
    const fn = time => {
        time = String(time);
        return time.length == 2 ? time : '0' + time;
    };
    switch (type) {
        case 'dd':
            return fn(dd);
        case 'hh':
            return fn(hh);
        case 'mm':
            return fn(mm);
        case 'ss':
            return fn(ss);
    }
}