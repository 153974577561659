import { downloadClick } from './api.js'
function changeFn (el, binding) {
  let modifiers = Object.keys(binding.modifiers)
  let direction = modifiers[0]
  let balance = modifiers[1]

  let val = binding.value
  if (!val.includes('.')) {
    val = val + '.00000000000000000'
  } else if (val.split('.')[1].length < 17) {
    let ccc = val.split('.')[1]
    while (ccc.length < 17) {
      ccc = ccc + '0'
    }
    val = val.split('.')[0] + '.' + ccc
  }
  // 分为单位
  let front = val.split('.')[0]
  let back = val.split('.')[1]

  let a = (+front / 100).toFixed(2)
  let show = a.toLocaleString('en-US') + ''
  let all = show + back
  let c = all.substring(0, 16)
  let d = all.substring(16, all.length)

  let oTip = document.createElement('div')
  let showDiv = document.createElement('span')

  showDiv.style.cssText =
    'overflow: hidden;white-space: nowrap;text-overflow: ellipsis;'
  showDiv.innerText = show

  oTip.className = balance || ''
  showDiv.className = balance || ''

  oTip.innerHTML = c + '<br>' + d
  el.style.position = 'relative'
  el.style.overflow = 'unset'
  el.appendChild(oTip)
  el.appendChild(showDiv)
  let css = `position:absolute;
        color:#fff;
        line-height:1.5;
        font-size:10rem;
        padding:5rem 10rem;
        background-color:rgba(0,0,0,0.7);
        border-radius: 10rem;
        opacity:0;
        z-index:1;`

  if (direction == 'bottom') {
    oTip.style.cssText = `${css}transform:translate(-20%,50%);bottom:-100%`
  } else if (direction == 'right') {
    oTip.style.cssText = `${css}transform:translate(110%,-20%);`
  } else if (direction == 'left') {
    oTip.style.cssText = `${css}transform:translate(-110%,-20%);`
  } else {
    oTip.style.cssText = `${css}transform:translate(-20%,-50%);top:-100%`
  }
  showDiv.onmouseenter = function () {
    oTip.style.opacity = 1
  }
  showDiv.onmouseleave = function () {
    oTip.style.opacity = 0
  }
}

function css (frameUrl) {
  return `
  position:absolute;
  background-image: url(${frameUrl});
  background-size: 100%;
  background-repeat: no-repeat;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1;`
}

async function toFrame (el, binding) {
  let modifiers = Object.keys(binding.modifiers)[0] //toUrl
  let frameUrl = binding.value || ''
  if (modifiers == 'toUrl' && frameUrl) {
    const { url } = await downloadClick(binding.value)
    frameUrl = url
  }
  let frameDiv = document.createElement('div')
  frameDiv.className = binding.arg || ''
  frameDiv.style.cssText = css(frameUrl)
  let frameSpan = document.createElement('span')
  frameSpan.style.cssText = `display:block;position:relative`

  el.parentNode.replaceChild(frameSpan, el)
  frameSpan.appendChild(el)

  frameSpan.children[0].style.cssText = 'border-radius:50%;overflow:hidden'
  if (frameUrl) {
    el.style.transform = 'scale(0.85)'
  }
  if (!frameUrl) {
    return
  }
  frameSpan.appendChild(frameDiv)
}
async function toFrame2 (el, binding) {
  let modifiers = Object.keys(binding.modifiers)[0] //toUrl
  let frameUrl = binding.value || ''
  let frameDiv = document.createElement('div')
  frameDiv.className = binding.arg || ''
  if (modifiers == 'toUrl' && frameUrl) {
    const { url } = await downloadClick(binding.value)
    frameUrl = url
  }
  el.style.transform = 'scale(1)'
  if (frameUrl) {
    el.style.transform = 'scale(0.85)'
  }
  frameDiv.style.cssText = css(frameUrl)
  if (!frameUrl) {
    return
  }
  el.parentNode.appendChild(frameDiv)
}
export default {
  install (Vue, options) {
    Vue.directive('formatAmount', {
      inserted (el, binding) {
        changeFn(el, binding)
      },
      componentUpdated (el, binding) {
        if (Object.keys(binding.modifiers)[1] == 'balance0330') {
          const clearDom = document.querySelectorAll('.balance0330')
          Array.from(clearDom).forEach(item => {
            item.remove()
          })
          changeFn(el, binding)
        }
      }
    })
    Vue.directive('avatarFrame', {
      inserted (el, binding) {
        toFrame(el, binding)
      },
      componentUpdated (el, binding) {
        if (binding.arg) {
          if (
            binding.arg.length > 1 &&
            binding.arg[1] == 'onceUpdate' &&
            el.parentNode.children.length > 1
          ) {
            return
          }
          const clearDom = document.querySelectorAll(`.${binding.arg}`)
          Array.from(clearDom).forEach(item => {
            item.remove()
          })
          setTimeout(() => {
            toFrame2(el, binding)
          }, 0)
        }
      }
    })
    Vue.directive('sbs', {
      inserted (el, binding) {
        if (el.innerText.includes('..')) {
          let a = el.innerText.split('..')
          el.innerHTML = `${a[0]}..<span style='font-size: 13rem;'>${a[1]}</span>`
        }
      }
    })
  }
}
