import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { requireUserInfo, getMarketPlaceTag, downloadClick } from '../utils/api'
var Timer0414
function _setClaimNum (state, data) {
  state.claimNum = data
  // window.localStorage.setItem('claimNum', data)
}
export default new Vuex.Store({
  state: {
    userInfo: {},
    tierList: [],
    locationList: [],
    collectionsList: [],
    socialMedia: {},
    claimNum: 100, // claim a piece 领取了多少个
    nftCollectionNum: 0,
    userFrame: ''
  },
  mutations: {
    setUserFrame (state, val) {
      state.userFrame = val
    },
    setNCN (state, val) {
      state.nftCollectionNum = val
    },
    setTagList (state, { list }) {
      state.tierList = list[0].marketplaceTraitList
      state.locationList = list[1].marketplaceTraitList
      state.collectionsList = list[2].marketplaceTraitList
    },
    setClaimNum: _setClaimNum,
    setUserInfo (state, data) {
      const {
        collectionNum,
        userProfile,
        redPoint,
        brick,
        claimNum,
        effectiveWithdrawAmount,
        totalWithdrawAmount,
        userFrame
      } = data
      _setClaimNum(state, claimNum)
      // state.claimNum = claimNum
      state.userInfo = userProfile
      state.userInfo.collectionNum = collectionNum
      state.userInfo.redPoint = redPoint
      state.userInfo.brick = brick
      state.userInfo.effectiveWithdrawAmount = effectiveWithdrawAmount
      state.userInfo.totalWithdrawAmount = totalWithdrawAmount
      state.userInfo.userFrame = userFrame
      window.localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
      // window.localStorage.setItem('claimNum', claimNum)
    },
    initUserInfo (state) {
      state.userInfo = {}
      state.socialMedia = {}
      state.claimNum = 100 // claim a piece 领取了多少个
      state.nftCollectionNum = 0
      state.userFrame = ''
    },
    setSocialMedia (state, data) {
      // window.localStorage.setItem('socialMedia', JSON.stringify(data))
      state.socialMedia = data || {}
    }
  },
  actions: {
    getUserInfo (context) {
      if (Timer0414) clearTimeout(Timer0414)
      Timer0414 = setTimeout(async () => {
        clearTimeout(Timer0414)
        let userId = ''
        const userData = await requireUserInfo(userId)
        if (userData.userProfile && userData.userProfile.avatar) {
          userData.userProfile.avatarOri = userData.userProfile.avatar
        }
        if (
          Object.keys(userData).length !== 0 &&
          Boolean(userData.userProfile.avatar)
        ) {
          const { url } = await downloadClick(userData.userProfile.avatar)
          if (url) {
            userData.userProfile.avatar = url
          }
        }
        if (userData.userFrame) {
          const { url } = await downloadClick(userData.userFrame)
          context.commit('setUserFrame', url)
        }
        if (Object.keys(userData).length == 0) return
        context.commit('setUserInfo', userData)
      }, 1000)
    },
    async getTagList (context) {
      const list = await getMarketPlaceTag()
      context.commit('setTagList', list)
    }
  }
})
