import Vue from 'vue'
import App from './App.vue'
import antDesign from "ant-design-vue"
import { message } from 'ant-design-vue'
import store from "./store/index"
import "ant-design-vue/dist/antd.css"
import "./assets/font/font.css"
import router from "./router"
import { setHtmlFontSize } from "./libs/jsm/reset"
import directives from './utils/directive'
Vue.use(antDesign);
Vue.config.productionTip = false;
Vue.prototype.$bus=new Vue()
Vue.prototype.$message = message
Vue.prototype.$message.config({
    top: `300rem`,
    duration: 3,
    maxCount: 1,
});
Vue.prototype.$checkLogin = () => {
    const token = window.localStorage.getItem("token") || "";
    return token == "" ? false : true;
}
Vue.use(directives)
Vue.prototype.$getUserInfo = () => {
    store.dispatch("getUserInfo")
};
store.dispatch("getUserInfo");
window.onresize = setHtmlFontSize;
setHtmlFontSize();
new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')